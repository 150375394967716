const projects = [
    {
        title: 'Issue tracker built with Vue and Ruby on Rails',
        tags: ['Vue.js', 'Ruby on Rails', 'Cypress.io', 'Heroku', 'Postgres', 'Redis'],
        image: 'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/blog-image.jpg?alt=media&token=24551848-b386-41b3-862c-baf292c76f13',
        link: 'https://issue-tracker-portal.herokuapp.com/',
        description: 'This blog is about how I built an “Issue tracker” application from scratch, the tools and technology I chose (and why) , the steps involved and of course my learnings. Project is running on free tiers on Heroku, there may be delay in loading the application.',
        readMore: 'Read more',
        readMoreLink: 'https://devayansarkar.medium.com/building-an-issue-tracker-application-from-scratch-77765240c1a8'
    },
    {
        title: 'Docker image with Java 11, Maven 3, NodeJS 14 and NPM 7.',
        tags: ['Docker', 'Java11', 'Node14', 'NPM7', 'Maven3'],
        image: 'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/docker.jpg?alt=media&token=eec31d63-014e-448d-88aa-db51b2ddef9c',
        link: 'https://hub.docker.com/repository/docker/devayansarkar/maven_java_nodejs',
        description: 'Ubuntu based Docker image with AdoptOpenJDK 11, NodeJS 14.x, Maven 3.x and NPM 7.x. This was created to help developers build Java and NodeJs 114 based application using the same base image.',
        button: 'View'
    },
    {
        title: 'GraphQL using Firebase',
        tags: ['Firebase', 'GraphQL', 'Serverless'],
        image: 'https://miro.medium.com/max/2000/1*yiC954vKKhFrVG0Cixof0Q.png',
        link: 'https://github.com/devayansarkar/graphql-with-firebase',
        description: 'Firebase is comprehensive app development platform that allows you to build your apps at scale. And with servless you can build web scale apps at a fraction of cost, almost free. This blog shows how we can run a GraphQL server on a serverless function.',
        button: 'View',
        readMore: 'Read more',
        readMoreLink: 'https://medium.com/swlh/graphql-server-using-firebase-97c9ec101948',
    },
    {
        title: 'Docker image with Java 11, NodeJS 12 and Maven 3',
        tags: ['Docker', 'Java11', 'Node12', 'Maven3'],
        image: 'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/docker.jpg?alt=media&token=eec31d63-014e-448d-88aa-db51b2ddef9c',
        link: 'https://hub.docker.com/repository/docker/devayansarkar/maven-node-openjdk',
        description: 'Ubuntu based Docker image with AdoptOpenJDK 11, NodeJS 12.x and Maven 3.x. This was created to help developers build Java and NodeJs based application using the same base image.',
        button: 'View'
    },
]

export default projects