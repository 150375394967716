import { Link } from 'gatsby'
import React from 'react'
import { InfoCard } from '../components/card'
import Footer from '../components/footer'
import Layout from '../components/layout'
import NavigationBar from '../components/navigation-bar'
import Seo from '../components/seo'
import projects from '../content/projects.content'
import '../styles/layout.styles.sass'
import MenuButton from "../components/menu-button"
import Menu from "../components/menu"

const BlogPage = () => {
    const [isOpen, setOpen] = React.useState(false)

    return <Layout>
        <Seo title="My projects" description="I build small pet projects to keep myself upto date and learn new technologies" meta="fullstack, software, developer, amsterdam" />
        <MenuButton page={'projects-page-bar'} open={isOpen} onClickOperation={setOpen} />
        <Menu open={isOpen} onCloseOperation={setOpen} />
        <div className={`page-container projects-page-container ${!isOpen ? 'page-container-open' : 'page-container-close'}`}>
            <div className={'navigation-container-left'}>
                <Link to="/"><NavigationBar title={'About me'} page={'about-me'} /></Link>
                <Link to="/skill"><NavigationBar title={'My skills'} page={'skills'} /></Link>
                <Link to="/blog"> <NavigationBar title={'Blogs'} page={'blogs'} /></Link>

            </div>
            <div className={'content-container'}>
                <div className={'layout-container'}>
                    <div className={'title projects-title'}>
                        <em>Pet projects</em>
                    </div>
                    {
                        projects.map((blog) => {
                            return <div className={'blog'} key={blog.title}>
                                <InfoCard info={blog} key={blog.title} />
                            </div>
                        })
                    }

                </div>
                <Footer />
            </div>
            <div className={'navigation-container-right'}>
                <Link to="/contact"><NavigationBar title={'Contact me'} page={'contact-me'} /></Link>
            </div>
        </div>
    </Layout>
}

export default BlogPage